import StudentGroupTable from '../../components/studentsComponents/tables/StudentGroupTable'

const StudentGroupsPage = () => {
  return (
        <>
            <StudentGroupTable></StudentGroupTable>
        </>
  )
}

export default StudentGroupsPage
