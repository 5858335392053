import StaffTable from '../../components/adminComponents/tables/StaffTable'

const AdminStaffPage = () => {
  return (
    <>
      <StaffTable></StaffTable>
    </>
  )
}
export default AdminStaffPage
