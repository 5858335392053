import SectionTable from '../../components/professorComponents/tables/SectionTable'

const ProfessorSectionPage = () => {
  return (
    <>
      <SectionTable></SectionTable>
    </>
  )
}
export default ProfessorSectionPage
