import GroupTable from '../../components/professorComponents/tables/GroupTable'

const ProfessorGroupPage = () => {
  return (
        <>
         <GroupTable></GroupTable>
        </>
  )
}
export default ProfessorGroupPage
