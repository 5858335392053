// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Ensure the navbar has no margin at the bottom */
#nav {
    margin-bottom: 0; /* Remove the bottom margin so background flows directly under it */
}
body, html {
    margin: 0;
    padding: 0;
    overflow-x: hidden;  /* Prevent horizontal scrolling */
  }`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,kDAAkD;AAClD;IACI,gBAAgB,EAAE,mEAAmE;AACzF;AACA;IACI,SAAS;IACT,UAAU;IACV,kBAAkB,GAAG,iCAAiC;EACxD","sourcesContent":["/* Ensure the navbar has no margin at the bottom */\n#nav {\n    margin-bottom: 0; /* Remove the bottom margin so background flows directly under it */\n}\nbody, html {\n    margin: 0;\n    padding: 0;\n    overflow-x: hidden;  /* Prevent horizontal scrolling */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
