import MyGroup from '../../components/studentsComponents/MyGroup'

const StudentMyGroupPage = () => {
  return (
        <>
            <MyGroup></MyGroup>
        </>
  )
}

export default StudentMyGroupPage
