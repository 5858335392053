import ProjectTable from '../../components/professorComponents/tables/ProjectTable'
const ProfessorProjectPage = () => {
  return (
    <>
      <ProjectTable></ProjectTable>
    </>
  )
}

export default ProfessorProjectPage
