// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#m{
    margin: 0;
    padding: 0;
     
}

.nav-container {
    width: 100%;
  }
  
  .nav__logo img {
    height: 40px; /* Adjust according to your logo size */
  }
  
  .AppBar {
    background-color: transparent;
    box-shadow: none;
  }
  
  .Toolbar {
    display: flex;
    justify-content: space-between;
  }
  
  .nav-link {
    color: white;
    text-transform: uppercase;
    margin-right: 20px;
  }
  
  .nav__logo {
    display: flex;
    align-items: center;
  }
  
  .nav__section-divider {

    width: 100%;
    margin: 0 1rem;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/StaticComponents/NavBar/ResponsiveAppBar.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;;AAEd;;AAEA;IACI,WAAW;EACb;;EAEA;IACE,YAAY,EAAE,uCAAuC;EACvD;;EAEA;IACE,6BAA6B;IAC7B,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,8BAA8B;EAChC;;EAEA;IACE,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,mBAAmB;EACrB;;EAEA;;IAEE,WAAW;IACX,cAAc;EAChB","sourcesContent":["#m{\n    margin: 0;\n    padding: 0;\n     \n}\n\n.nav-container {\n    width: 100%;\n  }\n  \n  .nav__logo img {\n    height: 40px; /* Adjust according to your logo size */\n  }\n  \n  .AppBar {\n    background-color: transparent;\n    box-shadow: none;\n  }\n  \n  .Toolbar {\n    display: flex;\n    justify-content: space-between;\n  }\n  \n  .nav-link {\n    color: white;\n    text-transform: uppercase;\n    margin-right: 20px;\n  }\n  \n  .nav__logo {\n    display: flex;\n    align-items: center;\n  }\n  \n  .nav__section-divider {\n\n    width: 100%;\n    margin: 0 1rem;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
