import StudentTable from '../../components/professorComponents/tables/StudentTable'

const ProfessorStudentPage = () => {
  return (
    <>
      <StudentTable></StudentTable>
    </>
  )
}
export default ProfessorStudentPage
